module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://rab.drk-khs.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/emotion-cache-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#e60005","showSpinner":false},
    },{
      plugin: require('../plugins/pressless/gatsby-browser.js'),
      options: {"plugins":[],"hostUrl":"https://rab.cms.drk-khs.de","types":["WpPage","WpEinrichtung"],"contentField":"content"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":300000,"hardCacheData":false},"url":"https://rab.cms.drk-khs.de/graphql","debug":{"preview":true,"graphql":{"printIntrospectionDiff":true,"showQueryVarsOnError":true,"writeQueriesToDisk":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"timeout":800000,"perPage":100,"requestConcurrency":10,"previewRequestConcurrency":10,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"ContentType":{"excludeFieldNames":["canExport","connectedTaxonomies","contentNodes","deleteWithUser","description","excludeFromSearch","hasArchive","hierarchical","isContentNode","isFrontPage","isPostsPage","isRestricted","isTermNode","label","labels","menuIcon","menuPosition","public","publiclyQueryable","restBase","restControllerClass","showInAdminBar","showInGraphql","showInMenu","showInNavMenus","showInRest","showUi"]},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"presets":[{"options":{"type":{"Post":{"limit":100},"Page":{"limit":100},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true}}],"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://analytics.drk-khs.de/","siteUrl":"https://rab.drk-khs.de","disableCookies":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
